@import '../../css_variables/grid';
@import '../../css_variables/typography';

/* begin dev stuff */
.show-grid {
  margin-top: 10px;
  margin-bottom: 20px;
}

.show-grid [class*="span"] {
  background-color: #EEE;
  text-align: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  min-height: 30px;
  line-height: 30px;
}

.show-grid:hover [class*="span"] {
  background: #DDD;
}

#dev-grid {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000000;
  overflow: hidden;
  opacity: 0.2;
}

#dev-grid [class*=span] {
  height: 1400px;
  background-color: red;
}

/* end dev stuff */

//this grid stuff isn't playing too nice...
div.content {
  overflow: hidden;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.grid {
  width: $contentWidth + $gutterWidth; /* accounts for gutter on last item in the row */
  margin-right: -$gutterWidth;
  font-size: 0;
}

.grid > * {
  @include fontSizePxRems(16, 16);
}

.grid .grid {
  font-size: 0;
  margin-right: 0;
  text-align: left;
}

[class*=span] {
  margin-right: $gutterWidth;
  display: inline-block;
  vertical-align: top;
}

@mixin spanColumns($numberColumns) {
  width: $numberColumns * $columnWidth + ($numberColumns - 1) * $gutterWidth;
}

.grid .span1,
.grid .fill1 {
  @include spanColumns(1);
}

.grid .span2,
.grid .fill2 {
  @include spanColumns(2);
}

.grid .span3,
.grid .fill3 {
  @include spanColumns(3);
}

.grid .span4,
.grid .fill4 {
  @include spanColumns(4);
}

.grid .span5,
.grid .fill5 {
  @include spanColumns(5);
}

.grid .span6,
.grid .fill6 {
  @include spanColumns(6);
}

.grid .span7,
.grid .fill7 {
  @include spanColumns(7);
}

.grid .span8,
.grid .fill8 {
  @include spanColumns(8);
}

.grid .span9,
.grid .fill9 {
  @include spanColumns(9);
}

.grid .span10,
.grid .fill10 {
  @include spanColumns(10);
}

.grid .span11,
.grid .fill11 {
  @include spanColumns(11);
}

.grid .span12,
.grid .fill12 {
  @include spanColumns(12);
}

.show-grid.grid .grid {
  background-color: #CCC;
}

.show-grid.grid .grid [class*="span"] {
  background-color: #AAA;
}

.grid .grid > :last-child[class*="span"] {
  margin-right: 0;
}

.grid .grid.span11 {
  width: 880px;
}

.grid .grid.span10 {
  width: 800px;
}

.grid .grid.span9 {
  width: 720px;
}

.grid .grid.span8 {
  width: 640px;
}

.grid .grid.span7 {
  width: 560px;
}

.grid .grid.span6 {
  width: 480px;
}

.grid .grid.span5 {
  width: 400px;
}

.grid .grid.span4 {
  width: 320px;
}

.grid .grid.span3 {
  width: 240px;
}

.grid .grid.span2 {
  width: 160px;
}

.fill {
  width: 100%;
}

@mixin offset-calculator($numberColumns, $columnWidth: $columnWidth, $gutterWidth: $gutterWidth) {
  margin-left: $numberColumns * ($columnWidth + $gutterWidth);
}

.skip0 {
  margin-left: 0;
}

.skip1 {
  @include offset-calculator(1);
}

.skip2 {
  @include offset-calculator(2);
}

.skip3 {
  @include offset-calculator(3);
}

.skip4 {
  @include offset-calculator(4);
}

.skip5 {
  @include offset-calculator(5);
}

.skip6 {
  @include offset-calculator(6);
}

.skip7 {
  @include offset-calculator(7);
}

.skip8 {
  @include offset-calculator(8);
}

.skip9 {
  @include offset-calculator(9);
}

.skip10 {
  @include offset-calculator(10);
}

.skip11 {
  @include offset-calculator(11);
}

.skip12 {
  @include offset-calculator(12);
}

.hidden {
  display: none;
}

.shown {
  display: inherit;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.align-left,
.grid .align-left,
.large-align-left,
.grid .grid .align-left {
  text-align: left;
}

.align-right,
.large-align-right,
.grid .align-right,
.grid .grid .align-right {
  text-align: right;
}

.align-center,
.large-align-center,
.grid .align-center,
.grid .grid .align-center {
  text-align: center;
}

.grid .zero-margins {
  margin: 0;
}

@import '../../css_variables/breakpoints';

@media (max-width: $grid-medium-breakpoint) {
  /* 4 (or 3) fluid columns */
  .grid {
    width: 103%;
  }

  [class*=span] {
    margin-right: 3%;
  }

  .grid .span12,
  .grid .span11,
  .grid .span10,
  .grid .span9,
  .grid .span8,
  .grid .span7,
  .grid .span6,
  .grid .span5,
  .span4,
  .grid .span4 {
    width: 97%;
  }

  .span3,
  .grid .span3 {
    width: 72%;
  }

  .span2,
  .grid .span2 {
    width: 47%;
  }

  .span1,
  .grid .span1 {
    width: 22%;
  }

  .grid .small-span4,
  .grid .small-fill4,
  .grid .small-fill {
    width: 97%;
  }

  .grid .small-span3,
  .grid .small-fill3 {
    width: 72%;
  }

  .grid .small-span2point5,
  .grid .small-fill2point5 {
    width: 63.667%;
  }

  .grid .small-span2,
  .grid .small-fill2 {
    width: 47%;
  }

  .grid .small-span1point5,
  .grid .small-fill1point5 {
    width: 30.333%;
  }

  .grid .small-span1,
  .grid .small-fill1 {
    width: 22%;
  }

  .grid .small-span4.grid {
    width: 100%;
  }

  .grid .small-span4.grid .small-span4,
  .grid .small-span4.grid .small-fill4 {
    width: 97%;
  }

  .grid .small-span4.grid .small-span3,
  .grid .small-span4.grid .small-fill3 {
    width: 72%;
  }

  .grid .small-span4.grid .small-span2,
  .grid .small-span4.grid .small-fill2 {
    width: 47%;
  }

  .grid .small-span4.grid .small-span1point5,
  .grid .small-span4.grid .small-fill1point5 {
    width: 34.5%;
  }

  .grid .small-span4.grid .small-span1,
  .grid .small-span4.grid .small-fill1 {
    width: 22%;
  }

  .grid .small-span3.grid {
    width: 75%;
  }

  .grid .small-span3.grid .small-span3,
  .grid .small-span3.grid .small-fill3 {
    width: 63.667%;
  }

  .grid .small-span3.grid .small-span2,
  .grid .small-span3.grid .small-fill2 {
    width: 63.667%;
  }

  .grid .small-span3.grid .small-span1point5,
  .grid .small-span3.grid .small-fill1point5 {
    width: 47%;
  }

  .grid .small-span3.grid .small-span1,
  .grid .small-span3.grid .small-fill1 {
    width: 30.333%;
  }

  .grid .small-span2.grid {
    width: 50%;
  }

  .grid .small-span2.grid .small-span2,
  .grid .small-span2.grid .small-fill2 {
    width: 97%;
  }

  .grid .small-span2.grid .small-span1,
  .grid .small-span2.grid .small-fill1 {
    width: 47%;
  }

  .grid .small-collapse {
    width: auto;
  }

  .grid .small-zero-margins {
    margin: 0;
  }

  .skip1 {
    margin-left: 25%;
  }

  .skip2 {
    margin-left: 50%;
  }

  .skip3 {
    margin-left: 75%;
  }

  .skip4,
  .skip5,
  .skip6 {
    margin-left: 100%;
  }

  .small-skip0 {
    margin-left: 0;
  }

  .small-skip1 {
    margin-left: 25%;
  }

  .small-skip2 {
    margin-left: 50%;
  }

  .small-skip3 {
    margin-left: 75%;
  }

  .small-skip4 {
    margin-left: 100%;
  }

  .small-skip5 {
    margin-left: 100%;
  }

  .small-skip6 {
    margin-left: 100%;
  }

  .small-skip1point5 {
    margin-left: 33.33333%;
  }

  .small-skip2point5 {
    margin-left: 66.66666%;
  }

  .grid .small-add-left-gutter {
    margin-left: 3%;
  }

  .grid .small-add-right-gutter {
    margin-right: 3%;
  }

  .grid .small-add-gutters {
    margin-left: 3%;
    margin-right: 3%;
  }

  .grid .small-span4.small-add-gutters {
    width: 91%;
  }

  .small-hidden {
    display: none;
  }

  .small-shown {
    display: inherit;
  }

  .small-invisible {
    visibility: hidden;
  }

  .small-visible {
    visibility: visible;
  }

  .small-align-right,
  .grid .small-align-right {
    text-align: right;
  }

  .small-align-left,
  .grid .small-align-left {
    text-align: left;
  }

  .small-align-center,
  .grid .small-align-center {
    text-align: center;
  }
}

@import '../../css_variables/breakpoints';

@media (max-width: $grid-large-breakpoint) {
  /* 4 (or 3) fluid columns, 5% gutters */
  .grid {
    width: 102%;
  }

  [class*=span] {
    margin-right: 2%;
  }

  .grid .span12,
  .grid .medium-fill12,
  .grid .span11,
  .grid .medium-fill11,
  .grid .span10,
  .grid .medium-fill10,
  .grid .span9,
  .grid .medium-fill9,
  .grid .span8,
  .grid .medium-fill8,
  .grid .span7,
  .grid .medium-fill7,
  .span6,
  .grid .span6,
  .grid .medium-fill6 {
    width: 98%;
  }

  .span5,
  .grid .span5,
  .grid .medium-fill5 {
    width: 81.333%;
  }

  .span4,
  .grid .span4,
  .grid .medium-fill4 {
    width: 64.667%;
  }

  .span3,
  .grid .span3,
  .grid .medium-fill3 {
    width: 48%;
  }

  .span2,
  .grid .span2,
  .grid .medium-fill2 {
    width: 31.333%;
  }

  .span1,
  .grid .span1,
  .grid .medium-fill1 {
    width: 14.667%;
  }

  .grid .medium-span6 {
    width: 98%;
  }

  .grid .medium-span5 {
    width: 81.333%;
  }

  .grid .medium-span4 {
    width: 64.667%;
  }

  .grid .medium-span3 {
    width: 48%;
  }

  .grid .medium-span2 {
    width: 31.333%;
  }

  .grid .medium-span1 {
    width: 14.667%;
  }

  .grid .medium-span6.grid {
    width: 100%;
  }

  .grid .medium-span5.grid {
    width: 83.333%;
  }

  .grid .medium-span5.grid .medium-span4,
  .grid .medium-span5.grid .medium-fill4 {
    width: 78%;
  }

  .grid .medium-span5.grid .medium-span3,
  .grid .medium-span5.grid .medium-fill3 {
    width: 58%;
  }

  .grid .medium-span5.grid .medium-span2,
  .grid .medium-span5.grid .medium-fill2 {
    width: 38%;
  }

  .grid .medium-span5.grid .medium-span1,
  .grid .medium-span5.grid .medium-fill1 {
    width: 18%;
  }

  .grid .medium-span4.grid {
    width: 66.667%;
  }

  .grid .medium-span4.grid .medium-span3,
  .grid .medium-span4.grid .medium-fill3 {
    width: 73%;
  }

  .grid .medium-span4.grid .medium-span2,
  .grid .medium-span4.grid .medium-fill2 {
    width: 48%;
  }

  .grid .medium-span4.grid .medium-span1,
  .grid .medium-span4.grid .medium-fill1 {
    width: 23%;
  }

  .grid .medium-span3.grid {
    width: 50%;
  }

  .grid .medium-span3.grid .medium-span2,
  .grid .medium-span3.grid .medium-fill2 {
    width: 64.667%;
  }

  .grid .medium-span3.grid .medium-span1,
  .grid .medium-span3.grid .medium-fill1 {
    width: 31.333%;
  }

  .grid .medium-span2.grid {
    width: 33.333%;
  }

  .grid .medium-span2.grid .medium-span1,
  .grid .medium-span2.grid .medium-fill1 {
    width: 48%;
  }

  .skip1 {
    margin-left: 16.667%;
  }

  .skip2 {
    margin-left: 33%;
  }

  .skip3 {
    margin-left: 50%;
  }

  .skip4 {
    margin-left: 66.667%;
  }

  .skip5 {
    margin-left: 83.333%;
  }

  .skip6,
  .skip7,
  .skip8,
  .skip9,
  .skip10,
  .skip11,
  .skip12 {
    margin-left: 100%;
  }

  .medium-skip0 {
    margin-left: 0;
  }

  .medium-skip1 {
    margin-left: 16.667%;
  }

  .medium-skip2 {
    margin-left: 33%;
  }

  .medium-skip3 {
    margin-left: 50%;
  }

  .medium-skip4 {
    margin-left: 66.667%;
  }

  .medium-skip5 {
    margin-left: 83.333%;
  }

  .medium-skip6 {
    margin-left: 100%;
  }

  .grid .medium-collapse {
    width: auto;
  }

  .grid .medium-zero-margins {
    margin: 0;
  }

  .grid .medium-add-left-gutter {
    margin-left: 2%;
  }

  .grid .medium-add-right-gutter {
    margin-right: 2%;
  }

  .grid .medium-add-gutters {
    margin-left: 2%;
    margin-right: 2%;
  }

  .grid .medium-span6.medium-add-gutters {
    width: 94%;
  }

  .medium-hidden {
    display: none;
  }

  .medium-shown {
    display: inherit;
  }

  .medium-invisible {
    visibility: hidden;
  }

  .medium-visible {
    visibility: visible;
  }

  .medium-align-right,
  .grid .medium-align-right {
    text-align: right;
  }

  .medium-align-left,
  .grid .medium-align-left {
    text-align: left;
  }

  .medium-align-center,
  .grid .medium-align-center {
    text-align: center;
  }
}
